<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="950"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改团队信息' : '新建团队信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 2 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 22 }, sm: { span: 24 } }"
    >
      <a-form-item label="团队名称:" name="corpName">
        <a-input
          v-model:value="form.corpName"
          placeholder="请输入团队名称"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="团队类型:" name="corpType">
        <a-select
          v-model:value="form.corpType"
          placeholder="请选择团队类型"
          allow-clear
        >
          <a-select-option
            v-for="item in corpTypeList"
            :key="item.dictDataName"
            :value="item.dictDataCode"
          >
            {{ item.dictDataName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="微信号:" name="weixinId">
        <a-input
          v-model:value="form.weixinId"
          placeholder="请输入微信号"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="联系方式:" name="phone">
        <a-input
          v-model:value="form.phone"
          placeholder="请输入联系方式"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="团队概述:" name="introduction">
        <a-textarea
          v-model:value="form.introduction"
          placeholder="请输入团队概述"
          :maxlength="100"
          :auto-size="{ minRows: 6 }"
          showCount
          allow-clear
        />
      </a-form-item>
      <a-form-item label="团队照片" name="corpPic">
        <a-upload
          list-type="picture-card"
          v-model:file-list="picList"
          @preview="handlePreview"
          :customRequest="({ file }) => uploadFile(file)"
          :remove="removeFile"
        >
          <div v-if="picList.length < 1">
            <plus-outlined />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          :bodyStyle="{ paddingTop: '50px' }"
          @cancel="previewVisible = false"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-item>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <a-form-item
            label="团队简介"
            :labelCol="{ span: 2, offset: 0 }"
            name="corpContent"
          >
            <tinymce-editor
              id="edit"
              v-model:value="form.content"
              :init="{ height: 400 }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
// import * as groupApi from '@/api/rnt/group'
// import * as operatorApi from '@/api/rnt/operator'
import * as dictApi from '@/api/dict/dictData.js'
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'
import * as corpApi from '@/api/corp/corp.js'
import * as corpContentApi from '@/api/corp/corpContent.js'
import TinymceEditor from '@/components/TinymceEditor'
import tinymce from 'tinymce'

function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'storeInfoEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    PlusOutlined,
    TinymceEditor
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      uploadUrl: setting.uploadUrl,
      picList: [],
      previewVisible: false,
      previewImage: '',
      // 编辑弹窗表单验证规则
      rules: {
        // groupId: [
        //   {
        //     required: true,
        //     message: '请输入站点组名称',
        //     type: 'string',
        //     trigger: 'blur'
        //   },
        //   {
        //     required: true,
        //     message: '请输入所属运营商',
        //     type: 'string',
        //     trigger: 'blur'
        //   }
        // ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      corpTypeList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
        this.getCorpContent(this.form.corpId)
        if (this.form.corpPic) {
          this.picList[0] = {
            uid: '-4',
            name: 'image.png',
            status: 'done',
            url: this.form.corpPic
          }
        }
      } else {
        this.form = {}
        this.picList = []
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.queryStoreType()
  },
  methods: {
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (file.uid === '-4') {
              this.picList = []
              this.form.corpPic = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      console.log(formData, file)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.picList[0] = {
              uid: '-4',
              name: 'image.png',
              status: 'done',
              url: res.data.location
            }
            this.form.corpPic = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    // 获取团队富文本内容
    getCorpContent(id) {
      if (this.isUpdate) {
        corpContentApi
          .corpContentId(id)
          .then((res) => {
            if (res.code === 0) {
              if (res.data) {
                console.log('富文本0', res.data)
                this.form.content = res.data.corpContent
              } else {
                this.form.content = ''
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (!this.form.introduction) {
            var activeEditor = tinymce.activeEditor
            var editBody = activeEditor.getBody()
            activeEditor.selection.select(editBody)
            var textC = activeEditor.selection.getContent({ format: 'text' })
            console.log('textC', textC)
            this.form.introduction = textC
          }

          corpApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // /* 查询商家类型 */
    queryStoreType() {
      dictApi
        .getDictData({ dictCode: 'corpType' })
        .then((res) => {
          if (res.code === 0) {
            this.corpTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>
